// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.mastery-timeline-left-line {
    border-left: 3px solid black;
    padding-left: 20px;
}

.mastery-timeline-left-circle {
    position: relative;
    top: 22px;
    left: -27px;
    background-color: white;
    border: 2px solid black;
    border-radius: 100%;
    width: 11px;
    height: 11px;
}

.mastery-timeline-left-circle:hover {
    background-color: lightgray;
}

.mastery-timeline-left-line:hover {
    background-color: lightgray;
}

.mastery-timeline-left-line:hover .mastery-timeline-left-circle {
    background-color: lightgray;
}

.mastery-description-hidden {
    display: none;
}

// table line highlight background
.mastery-table-hover tbody tr:hover td {
    background-color: #c2c2c2;
}
